// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Elo9GqVYwjNLtL7n88wD{margin-top:20px;font-size:1em}.Elo9GqVYwjNLtL7n88wD .QeqXUFCNmQdmbbQSbv5V .wcke8szxeU2_laIJ5UTQ{float:left;color:#2ba31d}.Elo9GqVYwjNLtL7n88wD .QeqXUFCNmQdmbbQSbv5V .ukmS6BGCURECV021P8hg{color:#2ba31d;font-weight:600}`, "",{"version":3,"sources":["webpack://./public/Pages/Login/components/ShowMessage/ShowMessage.module.scss"],"names":[],"mappings":"AAEA,sBACC,eAAA,CACA,aAAA,CAGC,kEACC,UAAA,CACA,aATK,CAYN,kEACC,aAbK,CAcL,eAAA","sourcesContent":["$green: #2ba31d;\n\n.main {\n\tmargin-top: 20px;\n\tfont-size: 1em;\n\n\t.printError {\n\t\t.icon {\n\t\t\tfloat: left;\n\t\t\tcolor: $green;\n\t\t}\n\n\t\t.value {\n\t\t\tcolor: $green;\n\t\t\tfont-weight: 600;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `Elo9GqVYwjNLtL7n88wD`,
	"printError": `QeqXUFCNmQdmbbQSbv5V`,
	"icon": `wcke8szxeU2_laIJ5UTQ`,
	"value": `ukmS6BGCURECV021P8hg`
};
export default ___CSS_LOADER_EXPORT___;
