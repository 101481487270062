// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nTOktW_lrgIk5UPMwZMg{border:none;padding:0;margin:0;outline:none;cursor:pointer;text-decoration:none;display:inline-block;padding:0 20px 0 0;border-radius:2px;height:40px;background-color:#4285f4;color:var(--py-neutral-0);font-weight:600;line-height:40px}.nTOktW_lrgIk5UPMwZMg .rJywwmISbDuCT2piKeB2{position:absolute;top:1px;left:1px;width:38px;height:38px;background-color:var(--py-neutral-0);border-radius:1px}.nTOktW_lrgIk5UPMwZMg .rJywwmISbDuCT2piKeB2 img{position:absolute;top:4px;left:4px;width:30px;height:30px}.nTOktW_lrgIk5UPMwZMg .o1SECfXGLboF0y3IT5GH{margin-left:50px}.nTOktW_lrgIk5UPMwZMg:hover{background-color:#699ef6}`, "",{"version":3,"sources":["webpack://./public/Components/GoogleButton/GoogleLoginButton.module.scss"],"names":[],"mappings":"AAEA,sBACC,WAAA,CACA,SAAA,CACA,QAAA,CACA,YAAA,CACA,cAAA,CACA,oBAAA,CACA,oBAAA,CACA,kBAAA,CACA,iBAAA,CACA,WAAA,CAEA,wBAdO,CAeP,yBAAA,CACA,eAAA,CACA,gBAAA,CAEA,4CACC,iBAAA,CACA,OAAA,CACA,QAAA,CACA,UAAA,CACA,WAAA,CACA,oCAAA,CACA,iBAAA,CAEA,gDACC,iBAAA,CACA,OAAA,CACA,QAAA,CACA,UAAA,CACA,WAAA,CAIF,4CACC,gBAAA,CAGD,4BACC,wBAAA","sourcesContent":["$color: #4285f4;\n\n.main {\n\tborder: none;\n\tpadding: 0;\n\tmargin: 0;\n\toutline: none;\n\tcursor: pointer;\n\ttext-decoration: none;\n\tdisplay: inline-block;\n\tpadding: 0 20px 0 0;\n\tborder-radius: 2px;\n\theight: 40px;\n\n\tbackground-color: $color;\n\tcolor: var(--py-neutral-0);\n\tfont-weight: 600;\n\tline-height: 40px;\n\n\t.iconWrapper {\n\t\tposition: absolute;\n\t\ttop: 1px;\n\t\tleft: 1px;\n\t\twidth: 38px;\n\t\theight: 38px;\n\t\tbackground-color: var(--py-neutral-0);\n\t\tborder-radius: 1px;\n\n\t\timg {\n\t\t\tposition: absolute;\n\t\t\ttop: 4px;\n\t\t\tleft: 4px;\n\t\t\twidth: 30px;\n\t\t\theight: 30px;\n\t\t}\n\t}\n\n\t.label {\n\t\tmargin-left: 50px;\n\t}\n\n\t&:hover {\n\t\tbackground-color: lighten($color, 8%);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `nTOktW_lrgIk5UPMwZMg`,
	"iconWrapper": `rJywwmISbDuCT2piKeB2`,
	"label": `o1SECfXGLboF0y3IT5GH`
};
export default ___CSS_LOADER_EXPORT___;
