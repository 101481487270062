// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.g_STkFE_EUr01sncLP1w{margin-top:30px;text-align:center}.g_STkFE_EUr01sncLP1w .WQTDMC16BWxgpL7ICnQF{display:inline-block;padding:5px 5px;vertical-align:middle}`, "",{"version":3,"sources":["webpack://./public/Pages/Login/components/AuthenticationMethods/AuthenticationMethods.module.scss"],"names":[],"mappings":"AAAA,sBACC,eAAA,CACA,iBAAA,CAEA,4CACC,oBAAA,CACA,eAAA,CACA,qBAAA","sourcesContent":[".ssoButtons {\n\tmargin-top: 30px;\n\ttext-align: center;\n\n\t.ssoButtonWrapper {\n\t\tdisplay: inline-block;\n\t\tpadding: 5px 5px;\n\t\tvertical-align: middle;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ssoButtons": `g_STkFE_EUr01sncLP1w`,
	"ssoButtonWrapper": `WQTDMC16BWxgpL7ICnQF`
};
export default ___CSS_LOADER_EXPORT___;
