// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iI_n0YIfsIlsy8OCC2Tg{border:1px solid var(--py-neutral-2);padding:0;margin:0;outline:none;cursor:pointer;text-decoration:none;display:inline-block;padding:0 20px 0 0;border-radius:2px;height:40px;background-color:var(--py-neutral-0);color:#26a977;font-weight:600;line-height:40px}.iI_n0YIfsIlsy8OCC2Tg ._YrzGTSUvH4i3WzfUCyL{position:absolute;top:1px;left:1px;width:38px;height:38px}.iI_n0YIfsIlsy8OCC2Tg ._YrzGTSUvH4i3WzfUCyL img{position:absolute;top:7px;left:7px;width:24px;height:24px}.iI_n0YIfsIlsy8OCC2Tg .z7x0SlQqCg8CP3OY8T5N{margin-left:40px}.iI_n0YIfsIlsy8OCC2Tg:hover{background-color:var(--py-neutral-1);border-color:var(--py-neutral-3)}`, "",{"version":3,"sources":["webpack://./public/Components/NetchexButton/NetchexButton.module.scss"],"names":[],"mappings":"AAGA,sBACC,oCAAA,CACA,SAAA,CACA,QAAA,CACA,YAAA,CACA,cAAA,CACA,oBAAA,CACA,oBAAA,CACA,kBAAA,CACA,iBAAA,CACA,WAAA,CAGA,oCAAA,CACA,aAAA,CACA,eAAA,CACA,gBAAA,CAEA,4CACC,iBAAA,CACA,OAAA,CACA,QAAA,CACA,UAAA,CACA,WAAA,CAEA,gDACC,iBAAA,CACA,OAAA,CACA,QAAA,CACA,UAAA,CACA,WAAA,CAIF,4CACC,gBAAA,CAGD,4BACC,oCAAA,CACA,gCAAA","sourcesContent":["// $color: #26a977;\n$color: #fff;\n\n.main {\n\tborder: 1px solid var(--py-neutral-2);\n\tpadding: 0;\n\tmargin: 0;\n\toutline: none;\n\tcursor: pointer;\n\ttext-decoration: none;\n\tdisplay: inline-block;\n\tpadding: 0 20px 0 0;\n\tborder-radius: 2px;\n\theight: 40px;\n\n\t// background-color: $color; color: #fff;\n\tbackground-color: var(--py-neutral-0);\n\tcolor: #26a977;\n\tfont-weight: 600;\n\tline-height: 40px;\n\n\t.iconWrapper {\n\t\tposition: absolute;\n\t\ttop: 1px;\n\t\tleft: 1px;\n\t\twidth: 38px;\n\t\theight: 38px;\n\n\t\timg {\n\t\t\tposition: absolute;\n\t\t\ttop: 7px;\n\t\t\tleft: 7px;\n\t\t\twidth: 24px;\n\t\t\theight: 24px;\n\t\t}\n\t}\n\n\t.label {\n\t\tmargin-left: 40px;\n\t}\n\n\t&:hover {\n\t\tbackground-color: var(--py-neutral-1);\n\t\tborder-color: var(--py-neutral-3);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `iI_n0YIfsIlsy8OCC2Tg`,
	"iconWrapper": `_YrzGTSUvH4i3WzfUCyL`,
	"label": `z7x0SlQqCg8CP3OY8T5N`
};
export default ___CSS_LOADER_EXPORT___;
