// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.F3wGhGlPGjgCfHVlL3b7{border:none;padding:0;margin:0;outline:none;cursor:pointer;text-decoration:none;display:inline-block;padding:0 20px 0 0;border-radius:50px;height:40px;background-color:#ed1c2e;color:var(--py-neutral-0);font-weight:600;line-height:40px}.F3wGhGlPGjgCfHVlL3b7 .bJBY2qKuvFJIZsP7AGIT{position:absolute;top:5px;left:20px;width:50px;height:30px;border-right:1px solid rgba(255,255,255,.35)}.F3wGhGlPGjgCfHVlL3b7 .bJBY2qKuvFJIZsP7AGIT img{position:absolute;top:5px;left:0px;height:20px}.F3wGhGlPGjgCfHVlL3b7 .i_bdau3fnfoATJodeN8K{margin-left:80px}.F3wGhGlPGjgCfHVlL3b7:hover{background-color:#f04251}`, "",{"version":3,"sources":["webpack://./public/Components/AdpButton/AdpButton.module.scss"],"names":[],"mappings":"AAEA,sBACC,WAAA,CACA,SAAA,CACA,QAAA,CACA,YAAA,CACA,cAAA,CACA,oBAAA,CACA,oBAAA,CACA,kBAAA,CACA,kBAAA,CACA,WAAA,CAEA,wBAdO,CAeP,yBAAA,CACA,eAAA,CACA,gBAAA,CAEA,4CACC,iBAAA,CACA,OAAA,CACA,SAAA,CACA,UAAA,CACA,WAAA,CACA,4CAAA,CAEA,gDACC,iBAAA,CACA,OAAA,CACA,QAAA,CACA,WAAA,CAIF,4CACC,gBAAA,CAGD,4BACC,wBAAA","sourcesContent":["$color: #ed1c2e;\n\n.main {\n\tborder: none;\n\tpadding: 0;\n\tmargin: 0;\n\toutline: none;\n\tcursor: pointer;\n\ttext-decoration: none;\n\tdisplay: inline-block;\n\tpadding: 0 20px 0 0;\n\tborder-radius: 50px;\n\theight: 40px;\n\n\tbackground-color: $color;\n\tcolor: var(--py-neutral-0);\n\tfont-weight: 600;\n\tline-height: 40px;\n\n\t.iconWrapper {\n\t\tposition: absolute;\n\t\ttop: 5px;\n\t\tleft: 20px;\n\t\twidth: 50px;\n\t\theight: 30px;\n\t\tborder-right: 1px solid rgba(255, 255, 255, 0.35);\n\n\t\timg {\n\t\t\tposition: absolute;\n\t\t\ttop: 5px;\n\t\t\tleft: 0px;\n\t\t\theight: 20px;\n\t\t}\n\t}\n\n\t.label {\n\t\tmargin-left: 80px;\n\t}\n\n\t&:hover {\n\t\tbackground-color: lighten($color, 8%);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `F3wGhGlPGjgCfHVlL3b7`,
	"iconWrapper": `bJBY2qKuvFJIZsP7AGIT`,
	"label": `i_bdau3fnfoATJodeN8K`
};
export default ___CSS_LOADER_EXPORT___;
