// dependencies
import { RewriteFrames as RewriteFramesIntegration } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { RouterProvider } from "react-router-dom";

// pages
import router from "./Router";

// utils
import beforeSendEventFunction from "../../react/utils/logging";

// config
import SentryConfig from "../../react/constants/Sentry";
// styles
import css from "./Main.module.scss";

// setup sentry
Sentry.init({
	dsn: SentryConfig.APIKey,
	environment: window.location.hostname,
	release: window?.PYENVIRONMENT?.release || "unknown",
	integrations: [
		new Sentry.BrowserTracing(),
		new RewriteFramesIntegration({
			iteratee: function (frame) {
				if (frame.filename) {
					frame.in_cdn = /https?:\/\/cdn\.performyard\.com/.test(frame.filename);
					frame.in_py = /https?:\/\/talent\.performyard\.com/.test(frame.filename);

					frame.in_app = frame.in_cdn | frame.in_py;
				}
				return frame;
			},
		}),
	],

	// Called for message and error events
	beforeSend: beforeSendEventFunction,
});

const queryClient = new QueryClient();

/**
 * Main component
 * I should probably use a proper router here since the public suite of pages
 * may start getting more complicated.
 */
const Main = () => {
	return (
		<QueryClientProvider client={queryClient}>
			<Sentry.ErrorBoundary fallback={null}>
				<div className={css.main}>
					<RouterProvider router={router} />
				</div>
			</Sentry.ErrorBoundary>
		</QueryClientProvider>
	);
};

export default Main;
