// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QLQQZLEXJISRSZrXIZqn{border:none;padding:0;margin:0;outline:none;cursor:pointer;display:block;padding:0 20px;height:40px;font-weight:600;line-height:40px;text-decoration:none;border-radius:2px}.QLQQZLEXJISRSZrXIZqn.jPqzXEOu7TlM6dqCwgHI{background-color:#02344a;color:var(--py-neutral-0)}.QLQQZLEXJISRSZrXIZqn.jPqzXEOu7TlM6dqCwgHI:hover{background-color:#035072}`, "",{"version":3,"sources":["webpack://./public/Components/SamlButton/SamlButton.module.scss"],"names":[],"mappings":"AAEA,sBACC,WAAA,CACA,SAAA,CACA,QAAA,CACA,YAAA,CAEA,cAAA,CACA,aAAA,CACA,cAAA,CACA,WAAA,CACA,eAAA,CACA,gBAAA,CACA,oBAAA,CACA,iBAAA,CAEA,2CACC,wBAlBS,CAmBT,yBAAA,CAEA,iDACC,wBAAA","sourcesContent":["$darkblue: #02344a;\n\n.main {\n\tborder: none;\n\tpadding: 0;\n\tmargin: 0;\n\toutline: none;\n\n\tcursor: pointer;\n\tdisplay: block;\n\tpadding: 0 20px;\n\theight: 40px;\n\tfont-weight: 600;\n\tline-height: 40px;\n\ttext-decoration: none;\n\tborder-radius: 2px;\n\n\t&.darkblue {\n\t\tbackground-color: $darkblue;\n\t\tcolor: var(--py-neutral-0);\n\n\t\t&:hover {\n\t\t\tbackground-color: lighten($darkblue, 8%);\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `QLQQZLEXJISRSZrXIZqn`,
	"darkblue": `jPqzXEOu7TlM6dqCwgHI`
};
export default ___CSS_LOADER_EXPORT___;
